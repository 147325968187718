import {useState, useEffect } from 'react'
import Header from './component/Header';
import Tasks from './component/Tasks';
import AddTask from './component/AddTask';

function App() {

const [showAddTask, setShowAddTask ] = useState(false)
const [tasks, setTasks] = useState([])

useEffect(() => {
	const getTasks = async () => {
		const taskFromServer = await fetchTasks()
		setTasks(taskFromServer)
}

	getTasks()

}, [])



// fetch daa 

const fetchTasks = async() => {
		const res = await fetch('http://localhost:5000/tasks')
		const data =  await res.json()
		return data
}



const fetchTask = async(id) => {
		const res = await fetch(`http://localhost:5000/tasks/${id}`)
		const data =  await res.json()
		return data
}





// delete Task
const deleteTask = async (id) => {
  await fetch(`http://localhost:5000/tasks/${id}`, {
  	method: 'DELETE'
  })

  setTasks(tasks.filter((task)=> task.id !== id))
}



const toggleRemainder = async (id) =>{

const taskToToggle = await fetchTask(id)
const updTask = {...taskToToggle, 
	reminder: !taskToToggle.reminder}

const res = await fetch(`http://localhost:5000/tasks/${id}`, {
	method: 'PUT',
	headers: {
		'Content-type': 'application/json'
	}, 
	body: JSON.stringify(updTask)
})


const data = await res.json()


    setTasks(
        tasks.map((task) => 
        	 task.id === id ? {...task, reminder: data.reminder}:task
        	)
    	)
}


// Add Task 

const addTask = async (task) => {
  const res =  await fetch('http://localhost:5000/tasks', {
  	method: 'POST',
  	headers: {
  		'Content-type': 'application/json'
  	}, 
  	body: JSON.stringify(task)
  })

 const data = await res.json()
 setTasks([...tasks, data])


 //    const id = Math.floor(Math.random() * 10000) + 1
	// const newTask = {id, ...task }
	// setTasks([...tasks, newTask])
}








// {condition? (condition_satisfied_statment): (condition_notSatsified_statment)}
return (
    <div className="container">
             <Header onAdd={() => setShowAddTask(!showAddTask)}
               showAdd={showAddTask}
                />
             {showAddTask &&  <AddTask onAdd={addTask} /> }
             {tasks.length > 0 ? (
             	<Tasks tasks={tasks} 
             	onDelete={deleteTask} onToggle ={toggleRemainder} />
             	):(
             	' No tasks to show'
             	)
             }
             
    </div>
  );
}

export default App;
// only one div is possible in App